import { getUrl, getBody } from "./"

export const processMenu = async ({ menu = {} } = {}) => {
    try {

        const url = getUrl({ env: menu.env });
        const body = getBody({ menu });
        const auth = `Bearer ${process.env.VUE_APP_API_AUTH}`;

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': auth
            },
            body,
        })

        if (!response.ok) {
            const message = `Error: ${response.status}`;
            return Promise.reject(message);
        }

        const returnBody = await response.text();

        return returnBody;

    } catch (err) {

        const message = `Error: ${err.message}`;
        return Promise.reject(message);

    }
};