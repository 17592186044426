import { ref, computed } from "vue";
import { getItem, getItems, addItem, deleteItem } from "@/services/items";
import { processMenu } from "@/services/menus";
import { useRouter } from 'vue-router'
import store from "@/store";

const menus = ref([]);

export const useMenus = () => {
    const router = useRouter();

    const orgs = computed(() => store.state.user.profile.orgs);

    const selected = ref(false);
    const selectedMenuIds = ref([]);

    const getMenus = async () => {
        store.dispatch("setLoading", true);
        try {

            const rawMenus = await getItems({ table: 'menus' });
            menus.value = rawMenus.filter(menu => {
                return orgs.value.includes(menu.org);
            });
            menus.value = menus.value.sort((a, b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

        } catch (error) {
            store.dispatch("setError", error);
            console.error({ error })
        }
        store.dispatch("setLoading", false);
    }

    const deleteMenu = async ({ id = null } = {}) => {
        if (confirm('Do you want to delete?')) {
            store.dispatch("setLoading", true);
            try {
                await deleteItem({ table: 'menus', id });
                store.dispatch("setSuccess", 'Menu deleted');
                router.go();
            } catch (error) {
                store.dispatch("setError", error);
                console.error({ error })
            }
            store.dispatch("setLoading", false);
        }
    }

    const runMenu = async ({ menuId = null } = {}) => {
        if (confirm('Do you want to run this menu?')) {
            store.dispatch("setLoading", true);
            try {
                const menu = await getItem({ table: 'menus', id: menuId });
                const menuReturn = await processMenu({ menu });
                await addItem({ table: 'menu_logs', item: { menuId, log: menuReturn } });
                store.dispatch("setSuccess", 'Menu run');
            } catch (error) {
                await addItem({ table: 'menu_logs', item: { menuId, log: error } });
                store.dispatch("setError", error);
                console.error({ error })
            }
            store.dispatch("setLoading", false);
        }
    }

    const selectAllMenus = () => {
        if (selected.value) {
            selectedMenuIds.value = [];
            selected.value = false;
        } else {
            selectedMenuIds.value = menus.value.map(menu => menu.id);
            selected.value = true;
        }
    }

    const runMenus = async () => {
        if (selectedMenuIds.value.length > 0 && confirm('Do you want to run these menus?')) {
            store.dispatch("setLoading", true);

            try {

                const selectedMenus = menus.value.filter(menu => {
                    return selectedMenuIds.value.includes(menu.id)
                });

                var menuResponses = await Promise.allSettled(
                    selectedMenus.map(async (selectedMenu) => {
                        const response = await processMenu({ menu: selectedMenu });
                        return { menu: selectedMenu, response }
                    })
                );
                await Promise.allSettled(
                    menuResponses.map(async (menuResponse) => {
                        const menuId = menuResponse.value.menu.id;
                        delete menuResponse.value.menu
                        await addItem({ table: 'menu_logs', item: { menuId, log: menuResponse } });
                    })
                );


                store.dispatch("setSuccess", 'Menus run');
            } catch (error) {
                store.dispatch("setError", error);
                console.error({ error })
            }
            store.dispatch("setLoading", false);
        }
    }

    return {
        menus: computed(() => menus.value),
        runMenu,
        runMenus,
        getMenus,
        deleteMenu,
        selectedMenuIds,
        selectAllMenus,
    }
}