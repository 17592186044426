<template>
  <menu-header
    title="Menus"
    :add="true"
    addTitle="add menu"
    url="/menu-add"
  ></menu-header>
  <main>
    <div class="max-w-7xl mx-auto px-4">
      <!-- Content -->

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <button
              @click="runMenus"
              class="router-link-active router-link-exact-active bg-white my-4 p-2 h-10 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Run Selected Menus
            </button>

            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <input
                        id="select-all"
                        name="select-all"
                        @click="selectAllMenus"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Store
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Store ID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Menu ID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Environment
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Run</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Logs</span>
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" v-for="menu in menus" :key="menu.id">
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      <input
                        :id="'select-menu-' + menu.id"
                        :name="'select-menu-' + menu.id"
                        :value="menu.id"
                        v-model="selectedMenuIds"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {{ menu.name }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ menu.siteId }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ menu.menuId }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ menu.env }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <router-link
                        :to="{ name: 'MenuEdit', params: { id: menu.id } }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <button
                        @click="runMenu({ menuId: menu.id })"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        Run
                      </button>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <router-link
                        :to="{ name: 'MenuLogs', params: { id: menu.id } }"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        Logs
                      </router-link>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <button
                        @click="deleteMenu({ id: menu.id })"
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import MenuHeader from "@/components/MenuHeader";
import { useMenus } from "./hook";

export default {
  name: "Menus",
  components: {
    MenuHeader,
  },
  setup() {
    const {
      menus,
      runMenu,
      runMenus,
      getMenus,
      deleteMenu,
      selectedMenuIds,
      selectAllMenus,
    } = useMenus();
    getMenus();

    return {
      menus,
      runMenu,
      runMenus,
      deleteMenu,
      selectedMenuIds,
      selectAllMenus,
    };
  },
};
</script>
