export const getUrl = ({ env = null } = {}) => {
    switch (env) {
        case 'dev':
            return 'https://us-central1-restaurant-dev-253219.cloudfunctions.net/webstorPullMenu';
        case 'uat':
            return 'https://us-central1-restaurant-uat.cloudfunctions.net/webstorPullMenu';
        case 'prod':
            return 'https://us-central1-restaurant-prd.cloudfunctions.net/webstorPullMenu';
        default:
            return 'https://us-central1-restaurant-dev-253219.cloudfunctions.net/webstorPullMenu';
    }
};