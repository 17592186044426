export const getBody = ({ client = 'rb-nz-kfc', menu = {} } = {}) => {

    switch (client) {
        case 'rb-nz-kfc':
            return `{
                "datasource": {
                    "storeId": ${menu.siteId},
                    "organisation": "rb-nz-kfc",
                    "provider": "mycentral",
                    "customer": "RB-NZ-KFC",
                    "mycentral": {
                        "applicationId": "CCEB3818-27FE-4B1E-9DAC-192952DD6019",
                        "siteId": ${menu.siteId},
                        "menuId": ${menu.menuId},
                        "locale": "en-GB",
                        "endpoint": {
                            "base": "https://kfckiosk.restaurantbrands.co.nz:81",
                            "securityMethod": "UIServices/SecurityService.svc?wsdl",
                            "orderMethod": "UIServices/OrderService.svc?wsdl"
                        },
                        "imagePaths": {
                            "base": "http://rbnz-kfc.s3.amazonaws.com/cache"
                        }
                    },
                    "customerData": {
                        "categoryMapping": "[{'id': 3503, 'name': 'Buckets and Wings', 'children': [], 'order': 0},{ 'id': 3504, 'name': 'Burgers','children': [], 'order': 1},{'id': 3508,'name': 'Drinks and Sides','children': [], 'order': 2},{'id': 3506,'name': 'Individual Meals','children': [], 'order': 3},{'id': 3507,'name': 'Lunch and Snacks','children': [], 'order': 4},{'id': 3505,'name': 'Twisters and Salad','children': [], 'order': 5}]"
                    }
                }
            }`;
        case 'rb-nz-tb':
            return `{
                "datasource": {
                  "storeId": "1",
                  "organisation": "rb-nz-tb",
                  "provider": "aloha",
                  "customer": "rb-nz-tb",
                  "aloha": {
                    "authBasicToken": "Rk1fVEJIS1NlcnZpY2VVc2VyOlI+Qjgoblo+MQ==",
                    "companyCode": "REBR001",
                    "siteId": "1",
                    "menuId": "1000",
                    "endpoint": {
                        "base": "https://api.ncrcloudconnect.hk/aoo/v1",
                        "menuMethod": "Menus",
                        "quickcomboMethod": "QuickCombos",
                        "siteMethod": "Site"
                    },
                    "hiddenCategoryIds": [0],
                    "imagePaths": {
                      "base": "https://restaurantbrands.alohaorderonline.hk/Content.ashx?DesignId=1",
                      "productFilenamePrefix": "native_menuitem_thumbnail_",
                      "categoryFilenamePrefix": "native_submenu_thumbnail_",
                      "modifierFilenamePrefix": "native_modifier_thumbnail_",
                      "salesitemFilenamePrefix": "native_salesitem_thumbnail_",
                      "productsFilenameSuffix": "",
                      "imagesCompanyCode": "",
                      "categoryDesignId": "",
                      "productsDesignId": ""
                    }
                  },
                  "customerData": {
                      "vouchersMapping": []
                  }
                }
              }`;
        default:
            return `{
                "datasource": {
                    "storeId": ${menu.siteId},
                    "organisation": "rb-nz-kfc",
                    "provider": "mycentral",
                    "customer": "RB-NZ-KFC",
                    "mycentral": {
                        "applicationId": "CCEB3818-27FE-4B1E-9DAC-192952DD6019",
                        "siteId": ${menu.siteId},
                        "menuId": ${menu.menuId},
                        "locale": "en-GB",
                        "endpoint": {
                            "base": "https://kfckiosk.restaurantbrands.co.nz:81",
                            "securityMethod": "UIServices/SecurityService.svc?wsdl",
                            "orderMethod": "UIServices/OrderService.svc?wsdl"
                        },
                        "imagePaths": {
                            "base": "http://rbnz-kfc.s3.amazonaws.com/cache"
                        }
                    },
                    "customerData": {
                        "categoryMapping": "[{'id': 3503, 'name': 'Buckets and Wings', 'children': [], 'order': 0},{ 'id': 3504, 'name': 'Burgers','children': [], 'order': 1},{'id': 3508,'name': 'Drinks and Sides','children': [], 'order': 2},{'id': 3506,'name': 'Individual Meals','children': [], 'order': 3},{'id': 3507,'name': 'Lunch and Snacks','children': [], 'order': 4},{'id': 3505,'name': 'Twisters and Salad','children': [], 'order': 5}]"
                    }
                }
            }`;
    }

};